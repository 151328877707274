/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline exception migrated from TSLint without explicit justification
 *	> 'observable' import from 'mobx' is restricted. */
import { observable } from 'mobx';
import { trace } from 'owa-trace';
import { createErrorWithoutStackTraceLimit } from './createErrorWithoutStackTraceLimit';

let needsToRun = process.env.NODE_ENV == 'dev';
const proxyTargetSymbol = Symbol('MobX proxy target');

export default function patchMobX() {
    if (needsToRun) {
        needsToRun = false;
        const originalObjectFactory = observable.object;

        observable.object = function objectFactoryWrapper(
            sourceObject: any,
            decorators?: any,
            options?: any
        ) {
            // If the source object is already a proxy, we can just return the object it is a proxy to
            if (sourceObject[proxyTargetSymbol]) {
                return sourceObject[proxyTargetSymbol];
            } else if (!Object.isExtensible(sourceObject)) {
                // We can't proxy the object if it's not extensible
                return originalObjectFactory(sourceObject, decorators, options);
            }

            // Create the store object
            const objectInStore = originalObjectFactory(sourceObject, decorators, options);

            // Lock down the source object set so we don't accidentally access it
            proxifyObject(sourceObject, objectInStore);
            return objectInStore;
        };
    }
}

function proxifyObject(proxyObject: any, objectInStore: any) {
    const proxifyMobxErrorName = 'ProxifyMobxObject';
    const stackTrace = createErrorWithoutStackTraceLimit(proxifyMobxErrorName).stack;
    // Lock the setters of the source object to prevent accidental access and throw an error
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    Object.keys(proxyObject).forEach(key => {
        if (Object.getOwnPropertyDescriptor(proxyObject, key)?.configurable) {
            const mutatingStaleMobxErrorName =
                'MutatingStaleMobXObject: You are accessing a stale object, please use the object from the store instead.';
            Object.defineProperty(proxyObject, key, {
                enumerable: true,
                get() {
                    return objectInStore[key];
                },
                set() {
                    if (stackTrace) {
                        trace.warn(`${proxifyMobxErrorName}: ${stackTrace}`);
                    }
                    throw createErrorWithoutStackTraceLimit(mutatingStaleMobxErrorName);
                },
            });
        }
    });

    // Keep track of the object we're proxying to
    Object.defineProperty(proxyObject, proxyTargetSymbol, {
        enumerable: false,
        value: objectInStore,
    });
}
