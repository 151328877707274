import getAccountSourceListStore from '../store/accountSourceListStore';
import getAccountBySourceId from './getAccountBySourceId';
import { getAccountKeyForMailboxInfo } from 'owa-client-types';

/**
 * Used to obtain the account key for the global settings account
 * @param thorwIfNotInitialized Set to true if this function should throw if the global settings account key cannot be obtained
 * @returns AccountKey for the global settings account. Returns an empty string if there is no global settings account and thorwIfNotInitialized is false
 */
export default function getGlobalSettingsAccountKey(throwIfNotInitialized: boolean = true): string {
    const source = getAccountBySourceId(getAccountSourceListStore().globalSettingsSourceId);
    if (source?.mailboxInfo) {
        return getAccountKeyForMailboxInfo(source.mailboxInfo);
    }

    if (throwIfNotInitialized) {
        throw new Error('Failed to get global settings account');
    }

    return '';
}
