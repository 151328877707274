import type { RemovedAccount } from 'owa-account-source-list-types';
import type {
    AccountSourceList,
    CoprincipalAccountSource,
    IndexerAndMatchInfo,
} from '../store/schema/AccountSourceList';
import { IndexerMatchType } from '../store/schema/AccountSourceList';
import { isPersistenceIdIndexerEnabled } from 'owa-client-types/lib/isPersistenceIdIndexerEnabled';

/**
 * DEPRECATED: Use buildAccountKeyByUserIdentityMap() instead
 *
 * Builds the map of user identity to indexer value, this is only to be used when indexers
 * are based off of user identity. The results of this map should be the same as the indexer
 * value that is returned by the tryLookupUserIdentityBasedIndexer function in
 * shared/internal/owa-account-source-list/src/utils/initializeTryLookupIndexer.ts
 * @param storeData Specifies the store data for which the map is to be built
 * @returns Map with information about the indexer and the match type
 */
export default function buildIndexerByUserIdentityMap(
    storeData: AccountSourceList
): Map<string, IndexerAndMatchInfo> {
    if (isPersistenceIdIndexerEnabled()) {
        // This map is not used when the persistence id indexer is enabled so we can just return an empty map
        return new Map<string, IndexerAndMatchInfo>();
    }

    const indexerByUserIdentity = new Map<string, IndexerAndMatchInfo>();

    // First add the user identities of the accounts if the value is not already in the map
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    storeData.sources.forEach((accountSource: CoprincipalAccountSource, index: number) => {
        if (!indexerByUserIdentity.has(accountSource.mailboxInfo.userIdentity)) {
            indexerByUserIdentity.set(accountSource.mailboxInfo.userIdentity, {
                indexer: accountSource.mailboxInfo.userIdentity,
                accountIndex: index,
                matchType: IndexerMatchType.AccountUserIdentity,
            });
        }
    });

    // Next add the user identities of the removed accounts if the value is not already in the map
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    storeData.removedAccounts.forEach((removedAccount: RemovedAccount, index: number) => {
        if (!indexerByUserIdentity.has(removedAccount.mailboxInfo.userIdentity)) {
            indexerByUserIdentity.set(removedAccount.mailboxInfo.userIdentity, {
                indexer: removedAccount.mailboxInfo.userIdentity,
                accountIndex: index,
                matchType: IndexerMatchType.RemovedUserIdentity,
            });
        }
    });

    // Finally add the aliases of any account for which the user identity is not already in the map
    storeData.sources.forEach((accountSource: CoprincipalAccountSource, index: number) => {
        accountSource.aliases.forEach(alias => {
            if (!indexerByUserIdentity.has(alias)) {
                indexerByUserIdentity.set(alias, {
                    indexer: accountSource.mailboxInfo.userIdentity,
                    accountIndex: index,
                    matchType: IndexerMatchType.AccountAlias,
                });
            }
        });
    });

    return indexerByUserIdentity;
}
