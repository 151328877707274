import { LazyBootModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "AppBootMsal" */ './lazyFunctions')
);

export const lazyCreateMsalInstanceProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyCreateMsalInstance;
    return action.importAndExecute.bind(action);
});

export const lazyGetAuthTokenMsalProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyGetAuthTokenMsal;
    return action.importAndExecute.bind(action);
});

export const lazyGetAnchorMailboxProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyGetAnchorMailbox;
    return action.importAndExecute.bind(action);
});

export const lazyFetchAccessTokenFromMsalProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyFetchAccessTokenFromMsal;
    return action.importAndExecute.bind(action);
});

export const lazyAcquireAccessTokenMsalProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyAcquireAccessTokenMsal;
    return action.importAndExecute.bind(action);
});

export const lazyExecuteMsalNestedAppAuthRequestProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyExecuteMsalNestedAppAuthRequest;
    return action.importAndExecute.bind(action);
});

export const lazyOnActivityTimeoutErrorForMSALProxy = new LazyAction(lazyModule, m => {
    const action = m.lazyOnActivityTimeoutErrorForMSAL;
    return action.importAndExecute.bind(action);
});
