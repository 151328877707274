import { defaultAccountKey } from './getAccountKeyForMailboxInfo';

/**
 * This function will validate and compare two account keys
 * to let us know if they are of the same coprincipal account.
 *
 * A direct comparison of the user email addresses or userIdentity is no longer
 * sufficient as the same user can have multiple accounts.
 *
 * @param mailbox1AccountKey You can pass in undefined for ease of use and it will return false.
 * @param mailbox1AccountKey
 * @returns true if the two account keys are valid and of the same coprincipal account.
 */
export default function isSameCoprincipalAccountByAccountKeys(
    mailbox1AccountKey: string | undefined,
    mailbox2AccountKey: string | undefined
): boolean {
    let isSame = false;

    if (
        mailbox1AccountKey &&
        mailbox2AccountKey &&
        mailbox1AccountKey !== defaultAccountKey &&
        mailbox1AccountKey !== '' &&
        mailbox2AccountKey !== defaultAccountKey &&
        mailbox2AccountKey !== ''
    ) {
        isSame = mailbox1AccountKey === mailbox2AccountKey;
    }

    return isSame;
}
