import getAccountSourceListStore from '../store/accountSourceListStore';
import getAccountBySourceId from './getAccountBySourceId';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';

/**
 * DEPRECATED: Use getGlobalSettingsAccountKey() instead
 *
 * Used to obtain the indexer value for the global settings account
 * @param thorwIfNotInitialized Set to true if this function should throw if the global settings indexer value cannot be obtained
 * @returns Indexer value for the global settings account. Returns an empty string if there is no global settings account and thorwIfNotInitialized is false
 */
export default function getGlobalSettingsAccountIndexer(
    throwIfNotInitialized: boolean = true
): string {
    const source = getAccountBySourceId(getAccountSourceListStore().globalSettingsSourceId);
    if (source?.mailboxInfo) {
        return getIndexerValueForMailboxInfo(source.mailboxInfo);
    }

    if (throwIfNotInitialized) {
        throw new Error('Failed to get global settings account');
    }

    return '';
}
