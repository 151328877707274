import getCoprincipalAccountForIndexerValue from './getCoprincipalAccountForIndexerValue';
import type { CoprincipalAccountSource } from '../store/schema/AccountSourceList';

/**
 * Returns the CoprincipalAccountSource associated with the specified account key
 * @param accountKey Value obtained via a getAccountKeyFromMailboxInfo call
 * @returns CoprincipalAccountSource if found or undefined if not found
 */
export default function getCoprincipalAccountForAccountKey(
    accountKey: string,
    logIfNotFound: boolean = false
): CoprincipalAccountSource | undefined {
    return getCoprincipalAccountForIndexerValue(accountKey, logIfNotFound);
}
