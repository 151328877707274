import { logStartUsage } from 'owa-analytics-start/lib/logStartUsage';

export const netcoreCustomComparator: {
    [key: string]: (owaResponse: Promise<any>, netcoreResponse: Promise<any>) => Promise<any>;
} = {
    GetTimeZone: (owaResponse: any, netcoreResponse: any) => {
        if (JSON.stringify(owaResponse).length == JSON.stringify(netcoreResponse).length) {
            return netcoreResponse;
        }
        logStartUsage('NetCoreDiff', { actionName: 'GetTimeZone' });
        return owaResponse;
    },
};
