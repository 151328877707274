import type { MailboxInfo } from 'owa-client-types';
import { areMailboxInfoEqual } from '../selectors/getAccountByMailboxInfo';

export default function isSameMailboxInfo(
    mailboxInfoA: MailboxInfo,
    mailboxInfoB: MailboxInfo,
    skipGuidComparison?: boolean
): boolean {
    return areMailboxInfoEqual(mailboxInfoA, mailboxInfoB, skipGuidComparison);
}
